import Vue from 'vue';
import Component from 'vue-class-component';
import { ApiUser, getUser, isStoreAuthenticated } from '@/api/shopify/user';
import { Mutation, State } from 'vuex-class';
import { Route } from 'vue-router';
import { StoreType } from '@extTypes/api/customily/user';
import { Watch } from 'vue-property-decorator';
import { isWooCommerceStore, isEtsyStore, isShoplazzaStore, isAmazonStore } from '@/utils/stores';
import { getEtsyLogin, setEtsyRequestToken } from '@/api/shopify/etsy';
import { capitalize } from 'lodash';
import { AxiosError } from 'axios';
import StoreSelector from '@/components/StoreSelector.vue';
import config from '@/config/config';

@Component({
    name: 'App',
    components: {
        StoreSelector
    }
})
export default class App extends Vue {
    @State('user') user!: ApiUser | null;
    @State('store') store!: string;
    @Mutation('setStore') setStore!: (store: string | null) => void;

    @Watch('store')
    onStoreChange() {
        this.checkStoreAuthentication();
    }

    allowAllStoresSelector = false;
    storeAuthenticated = true;
    isOpenRoute = false;
    isEtsyStore = false;
    isAmazonStore = false;
    loginUrl = '';
    tokenSecret = '';
    tokenState = '';

    wideRoutes = ['/orders', '/products_preview'];

    get storeSelectorTitle(): string {
        if (!this.store) {
            return 'All stores';
        } else {
            return this.store;
        }
    }

    get appInstallUrl(): string {
        let url = '';
        if (!this.store || !this.user?.customilyUser) return url;

        const baseUrl = config.APP_SHOPIFY_BASE_URL;

        if (isWooCommerceStore(this.store, this.user.customilyUser.Stores)) {
            url = `https://${this.store}/wp-login.php`;
        } else if (this.isEtsyStore) {
            url = this.loginUrl;
        } else if (isShoplazzaStore(this.store, this.user.customilyUser.Stores)) {
            url = `${baseUrl}/shoplazza/auth?shop=${this.store}`;
        } else if (this.isAmazonStore) {
            url = `${baseUrl}/amazon/auth?shop=${this.store}`;
        } else {
            url = `${baseUrl}/shopify/auth?shop=${this.store}`;
        }
        return url;
    }

    get msgVerb() {
        const verb = this.isEtsyStore ? 'connect' : 'install';
        return {
            present: verb,
            past: `${verb}ed`,
            capitalized: capitalize(verb)
        };
    }

    get wooUserHasntInstalledYet() {
        const isWooUser = Boolean(new URL(location.href).searchParams.get('isWooUser'));
        //this means that our api call throwed 401 when trying to get the user, because the user hasn't installed
        return isWooUser && !this.user;
    }

    async created() {
        const {
            route: {
                matched: [parent]
            }
        } = this.$router.resolve({ path: location.pathname });

        this.isOpenRoute = this.$route.meta?.isOpen || !parent?.meta?.requireStore;
        this.checkStoreSelectorVisibility(this.$router.currentRoute.path);

        document.addEventListener('auth-error', () => {
            this.storeAuthenticated = false;
        });

        await this.getUserData();
    }

    mounted() {
        this.addRouterGuard();
    }

    addRouterGuard() {
        this.$router.beforeEach((to: Route, _from: Route, next) => {
            this.isOpenRoute = this.$route.meta?.isOpen;
            this.checkStoreSelectorVisibility(to.path);
            next();
        });
    }

    async getUserData() {
        if (this.isOpenRoute) return;

        try {
            await getUser();
        } catch {
            this.storeAuthenticated = false;
        } finally {
            this.checkStoreAuthentication();
        }
    }

    setStoreUrl(store: string | null) {
        this.setStore(store);
    }

    showAuthError() {
        // Remove any loading element
        document.querySelectorAll('.loading-container')[0]?.remove();
        this.storeAuthenticated = false;
    }

    openInstallLink(_event: MouseEvent, region = '') {
        if (this.isEtsyStore) setEtsyRequestToken(this.tokenSecret, this.tokenState, this.store);

        let newWindow = this.appInstallUrl;
        if (region) {
            newWindow += `&region=${region}`;
        }
        window.open(newWindow, '_blank');
    }

    async checkStoreAuthentication() {
        if (!this.store || !this.user) {
            this.storeAuthenticated = true;
            return;
        }

        try {
            let shop = this.store;
            if (this.$route.query.shop) {
                shop = this.$route.query.shop as string;
            }

            const store = await isStoreAuthenticated(shop);

            if (!store.authenticated) {
                this.storeAuthenticated = false;
            } else {
                this.storeAuthenticated = true;
                if (window.Appcues) {
                    const appCuesUsername = this.user?.customilyUser.Username + process.env.VUE_APP_APPCUES_SUFIX;
                    window.Appcues.identify(appCuesUsername, {
                        createdAt: new Date(this.user?.customilyUser?.CreatedDate as string).getTime(),
                        accountId: this.user?.customilyUser?.Id,
                        version: '2.0', // users on different versions may need to see different content
                        language: 'english', // for multi-language applications
                        stores: this.user?.customilyUser?.Stores.map((s) => `_${s.Type}_`).join(',')
                    });
                }
            }

            this.isEtsyStore = isEtsyStore(shop, this.user.customilyUser.Stores);
            if (this.isEtsyStore && !this.storeAuthenticated) {
                const etsyLogin = await getEtsyLogin();
                this.loginUrl = etsyLogin.url;
                this.tokenSecret = etsyLogin.codeVerification;
                this.tokenState = etsyLogin.state;
            }
            this.isAmazonStore = isAmazonStore(shop, this.user.customilyUser.Stores);
        } catch (_error) {
            const error = _error as AxiosError;
            if (error.request.status !== 401) {
                window.captureException(error as Error);
            }
            this.storeAuthenticated = false;
        }
    }

    checkStoreSelectorVisibility(path: string) {
        const allowAllStoresPaths = ['/sets'];
        this.allowAllStoresSelector = allowAllStoresPaths.includes(path);

        /**
         * On routes that should not be able to select 'All Stores' option,
         * select the first available store if the current one is null
         */
        if (!this.allowAllStoresSelector && !this.store && this.user) {
            const url = this.user.customilyUser.Stores.find(
                (s) =>
                    s.Type === StoreType.Shopify || s.Type === StoreType.WooCommerce || s.Type === StoreType.Shoplazza
            )?.Url;
            if (!url) {
                console.error('User does not have any Shopify stores!');
                return;
            }
            this.setStore(url);
        }
    }

    get isWideRoute() {
        const currentRoute = this?.$route?.path || '';
        return this.wideRoutes.includes(currentRoute) || currentRoute.startsWith('/reviewProducts/');
    }
}
